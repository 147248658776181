<template>
  <div>
    <el-table
      v-loading="loading"
      :data="editableContents"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column prop="title" :label="labels.LBL_NAME" sortable />

      <el-table-column align="right" width="300">
        <!-- <template slot="header" slot-scope="scope">
          <el-row type="flex">
            <el-input
              v-model="search"
              size="mini"
              :placeholder="labels.LBL_TYPE_2_SEARCH"
              class="ml-1"
            />
          </el-row>
        </template> -->

        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";

  export default {
    name: "EditableContents",

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        editableContents: (state) =>
          state.editableContent.editableContents.data,
      }),
    },

    created() {
      this.loadEditableContents();
    },

    methods: {
      loadEditableContents() {
        this.$store.dispatch("editableContent/getAllEditableContents");
      },

      handleEdit({ id }) {
        this.$router.push({ name: "Edit Content", params: { contentId: id } });
      },
    },
  };
</script>

<style></style>
