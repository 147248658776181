<template>
  <content-wrapper title="APE">
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane label="Company Detail">
        <company-detail />
      </el-tab-pane>
      <el-tab-pane label="Editable Contents">
        <editable-contents />
      </el-tab-pane>
    </el-tabs>
  </content-wrapper>
</template>

<script>
  import { labels } from "@/common";

  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import CompanyDetail from "./Show.vue";
  import EditableContents from "./editable-contents/Index.vue";

  export default {
    name: "Index",

    components: {
      ContentWrapper,
      CompanyDetail,
      EditableContents,
    },

    data() {
      return {
        labels,
      };
    },

    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>

<style></style>
